<template>
  <div class="wrap">
    <div class="pre_top">
      <div class="pre_top_flex">
        <div class="pre_flex_center">
          <!-- <van-image class="pre_info_img" :src="img1"></van-image
          > -->
          <span class="iconfont icon-duihao icon_style"></span>
          <span>证件上传</span>
        </div>
        <div class="pre_top_line"></div>
        <div class="pre_top_flex">
          <!-- <van-image class="pre_info_img" :src="img2"></van-image
          > -->
          <span class="pre_active">2</span>
          <span>信息填写</span>
        </div>
      </div>
    </div>
    <div class="pre_explain">您的个人信息仅用于企业入职，请放心填写！</div>
    <div class="pre_photo">
      <div class="next_basic">
        <div>基本信息</div>
        <div>
          <van-image
            class="next_basic_img"
            @click="showInfo"
            :src="see1"
          ></van-image>
          <van-popup v-model="isShowInfo" closeable round :style="{ height: '75%',width:'100%',paddingTop:'2rem',boxSizing:'borderBox' }">
            <van-image :src="formData.positive"></van-image>
            <van-image :src="formData.other"></van-image>
          </van-popup>
        </div>
      </div>
      <div class="myInfo_center">
        <van-field
          v-model="formData.name"
          name="name"
          placeholder="请输入姓名"
          required
          clearable
          label="姓名："
        />
        <van-field
          v-model="formData.card_code"
          name="card_code"
          placeholder="请输入身份证号码"
          required
          clearable
          label="身份证号码："
        />
        <van-field
          v-model="formData.phone"
          name="phone"
          disabled
          placeholder="请输入手机号码"
          required
          clearable
          label="手机号码："
        />
        <van-field
          v-model="formData.address"
          name="address"
          placeholder="请输入户籍"
          required
          clearable
          label="户籍："
        />
        <van-field
          v-model="formData.gender"
          name="gender"
          placeholder="请选择性别"
          clearable
          label="性别："
          disabled
        />
        <van-field
          v-model="formData.birthday"
          name="birthday"
          placeholder="请选择出生日期"
          clearable
          label="出生日期："
          disabled
          class="field_border"
        />
      </div>
    </div>
    <div class="pre_photob">
      <div class="next_basic">
        <div>银行卡信息</div>
        <div>
          <van-image
            class="next_basic_img"
            @click="showBank"
            :src="see2"
          ></van-image>
          <van-popup v-model="isShowBank" closeable round :style="{ height: '45%',width:'100%',paddingTop:'2rem',boxSizing:'borderBox' }">
            <van-image :src="formData.bank_pic"></van-image>
          </van-popup>
        </div>
      </div>
      <div class="myInfo_center">
        <van-field
          v-model="formData.bank_account"
          name="bank_account"
          placeholder="请输入银行卡号"
          required
          clearable
          label="银行卡号："
        />
        <van-field
          v-model="formData.bank_title"
          name="bank_title"
          placeholder="请输入开户行"
          required
          clearable
          label="开户行："
        />
      </div>
    </div>
    <div class="next_tab">
      <div class="next_tabL" @click="toMine">
        <span class="iconfont icon-wode font_size"></span>
      </div>
      <div class="next_tabLa" @click="toAddfankui">
        <span class="iconfont icon-kefu font_size"></span>
      </div>
      <div class="next_tabRa" @click="toPreviousInfo">上一步</div>
      <div class="next_tabR">
        <van-button class="margin_radius" @click="submitData"
          >提交审核</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img1: require("../assets/images/infoy.png"),
      img2: require("../assets/images/info2y.png"),
      see1: require("../assets/images/see.png"),
      see2: require("../assets/images/see2.png"),
      pid: "",
      access_token: "",
      pnid: "",
      positive:
        "https://image.yoshebao.com/temp/20220329/ad02df8d269efde50a201b70a1859469.png", //正面照
      other:
        "https://image.yoshebao.com/temp/20220308/969edd3c67652be64e31f704ef15c055.png", //反面照
      bank_pic:
        "https://image.yoshebao.com/temp/20220308/969edd3c67652be64e31f704ef15c055.png", //银行卡照
      isShowInfo: false, //是否展示身份证照
      isShowBank: false, //是否展示银行卡照
      formData: {
        other: "",
        positive: "",
        name: "",
        card_code: "",
        phone: "",
        bank_account: "",
        bank_title: "",
        address: "",
        bank_pic: "",
      },
    };
  },
  mounted() {
    let access_token = localStorage.getItem("access_token");
    this.access_token = access_token;
    let pid = localStorage.getItem("pid");
    this.pid = pid;
    let pnid = localStorage.getItem("pnid");
    this.pnid = pnid;
    this.getPhotoInfo();
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#F6F7FA");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {
    // 展示银行卡照
    showBank() {
      this.isShowBank = true;
    },
    //展示身份证照
    showInfo() {
      this.isShowInfo = true;
    },
    //前往反馈页面
    toAddfankui() {
      this.$router.push({ name: "fankui" });
    },
    //前往我的
    toMine() {
      this.$router.push({ name: "mine" });
    },
    toPreviousInfo() {
      this.$router.push({ name: "previousInfo" });
    },
    //获取个人信息
    getPhotoInfo() {
      this.$axios
        .get(
          "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
            this.pid +
            '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","status":"userstatus","shiming":"shiming_b","gender":"gender","birthday":"birthday"}'
        )
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            let isArray = returnData instanceof Array;
            let phone = localStorage.getItem("mobile");
            if (!isArray) {
              if (phone != "") {
                this.formData.phone = phone;
              } else {
                this.formData.phone = returnData.phone;
              }
              this.formData.shiming_b = returnData.shiming_b;
              this.formData.name = returnData.name;
              this.formData.card_code = returnData.card_code;
              this.formData.bank_account = returnData.bank_account;
              this.formData.bank_title = returnData.bank_title;
              this.formData.address = returnData.address;
              this.formData.positive = returnData.pic.split(",")[0];
              this.formData.other = returnData.pic.split(",")[1];
              this.formData.bank_pic = returnData.bank_pic;
              this.formData.gender = returnData.gender;
              this.formData.birthday = returnData.birthday;
              this.formData.phone = returnData.phone;
            } else {
              this.$toast({ message: res.data.msg });
            }
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    // 判断是否实名认证成功
    //提交审核
    //信息确认页面
    submitData() {
      if (this.formData.card_code) {
        if (this.formData.card_code.length != 18) {
          this.$toast({ message: "请输入18位身份证号码" });
          return false;
        }
      }
      this.formData.pid = this.pid;
      this.formData.pnid = this.pnid;
      let card_code = localStorage.getItem("card_code");
      if (
        !this.formData.name ||
        !this.formData.card_code ||
        !this.formData.bank_account ||
        !this.formData.bank_title ||
        !this.formData.address
      ) {
        this.$toast({ message: "请完善个人信息" });
      } else if (this.formData.card_code != card_code) {
        this.$dialog
          .confirm({
            message: "您填写的信息部分与身份证照片不一致",
            confirmButtonText: "确认修改",
            cancelButtonText: "取消",
            width: "80%",
            type: "warning",
          })
          .then(() => {
            this.formData.data_statusb = 2;
            this.formData.pic =
              "" + this.formData.positive + "," + this.formData.other + "";
            this.$axios
              .get(
                "/person/getperstatusz?idNo=" +
                  this.formData.card_code +
                  "&name=" +
                  this.formData.name +
                  "&cardNo=" +
                  this.formData.bank_account
              )
              .then((res) => {
                if (res.data.success) {
                  this.myCode = res.data.data.code;
                  this.myMessage = res.data.data.message;
                  this.formData.shiming_msg = this.myMessage;
                  //判断是否实名成功
                  if (this.myCode == 0) {
                    this.formData.shiming = 1;
                    // this.$toast({ message: this.myMessage });
                    this.$toast({ message: "已提交审核" });
                    this.$axios
                      .post("/person/edits", this.formData, {
                        headers: {
                          "content-type": "application/json",
                          Authorization: this.access_token,
                        },
                      })
                      .then((res) => {
                        if (res.data.success) {
                          this.$router.push({ name: "mine" });
                        } else {
                          this.$toast({ message: res.data.msg });
                        }
                      })
                      .catch(() => {});
                  } else {
                    this.$dialog
                      .confirm({
                        message:
                          "实名认证失败,一般都是银行卡错误造成，\n请尝试更换银行卡",
                        confirmButtonText: "确认提交",
                        cancelButtonText: "取消",
                        width: "80%",
                        type: "warning",
                      })
                      .then(() => {
                        this.formData.shiming = 0;
                        this.$axios
                          .post("/person/edits", this.formData, {
                            headers: {
                              "content-type": "application/json",
                              Authorization: this.access_token,
                            },
                          })
                          .then((res) => {
                            if (res.data.success) {
                              this.$toast({ message: "已提交审核" });
                              this.$router.push({ name: "mine" });
                            } else {
                              this.$toast({ message: res.data.msg });
                            }
                          })
                          .catch(() => {});
                      })
                      .catch(() => {});
                    this.$toast({ message: res.data.message });
                    // this.$toast({ message: this.myMessage });
                  }
                } else {
                  this.$toast({ message: res.data.message });
                }
              })
              .catch(() => {});
          })
          .catch(() => {});
      } else {
        this.formData.pic =
          "" + this.formData.positive + "," + this.formData.other + "";
        this.$axios
          .get(
            "/person/getperstatusz?idNo=" +
              this.formData.card_code +
              "&name=" +
              this.formData.name +
              "&cardNo=" +
              this.formData.bank_account
          )
          .then((res) => {
            if (res.data.success) {
              this.myCode = res.data.data.code;
              this.myMessage = res.data.data.message;
              this.formData.shiming_msg = this.myMessage;
              //判断是否实名成功
              if (this.myCode == 0) {
                this.formData.shiming = 1;
                this.$toast({ message: "已提交审核" });
                this.$axios
                  .post("/person/edits", this.formData, {
                    headers: {
                      "content-type": "application/json",
                      Authorization: this.access_token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      this.$router.push({ name: "mine" });
                    } else {
                      this.$toast({ message: res.data.msg });
                    }
                  })
                  .catch(() => {});
              } else {
                this.$dialog
                  .confirm({
                    message:
                      "实名认证失败,一般都是银行卡错误造成，\n请尝试更换银行卡",
                    confirmButtonText: "确认提交",
                    cancelButtonText: "取消",
                    width: "80%",
                    type: "warning",
                  })
                  .then(() => {
                    this.formData.shiming = 0;
                    this.$axios
                      .post("/person/edits", this.formData, {
                        headers: {
                          "content-type": "application/json",
                          Authorization: this.access_token,
                        },
                      })
                      .then((res) => {
                        if (res.data.success) {
                          this.$toast({ message: "已提交审核" });
                          this.$router.push({ name: "mine" });
                        } else {
                          this.$toast({ message: res.data.msg });
                        }
                      })
                      .catch(() => {});
                  })
                  .catch(() => {});
                this.$toast({ message: res.data.message });
                // this.$toast({ message: this.myMessage });
              }
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.icon_style{
  color: #5694ff;
  font-size: 1.5625rem;
}
.pre_active,.pre_actived{
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #5694ff;
  border-radius: 50%;
  text-align: center;
  line-height: 1.5625rem;
  color: #fff;
  margin-right: 0.3125rem;
}
.pre_actived{
  background-color: #fff;
  color: #ccc;
  border:1px solid #ccc;
}
.wrap {
  /* background-color: #F6F7FA; */
  padding-top: 0.625rem;
  box-sizing: border-box;
  /* padding-bottom: 1rem; */
}
.pre_top {
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  /* margin-top: 0.625rem; */
}

.pre_top_line {
  width: 7.5rem;
  height: 0.0625rem;
  background-color: rgba(51, 51, 51, 0.5);
}

.pre_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pre_flex_center {
  display: flex;
  align-items: center;
}

.pre_info_img {
  width: 1.5625rem;
  height: 1.5625rem;
}
.pre_explain {
  background-color: #e2efff;
  color: #2694fd;
  text-align: left;
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0 1.5625rem;
  box-sizing: border-box;
  margin-top: 0.3125rem;
}

.pre_photo,
.pre_photob {
  width: calc(100% - 30px);
  margin: 0 auto;
  border: 0.0625rem solid #ddd;
  height: 20.125rem;
  border-radius: 0.625rem;
  margin-top: 0.3125rem;
  background-color: #fff;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  box-sizing: border-box;
}

.pre_photob {
  height: 10rem;
}

.next_basic {
  display: flex;
  justify-content: space-between;
  padding: 0 0.3125rem;
  box-sizing: border-box;
}

.next_basic_img {
  width: 1.5625rem;
  height: 1.25rem;
}

.pre_marginb {
  margin-bottom: 5rem;
}

.font_size {
  font-size: 1.875rem;
}
.margin_radius {
  background-color: #4494ff !important;
  color: #fff !important;
  border: none !important;
}

.next_tab {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3.125rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
}

.next_tabL,
.next_tabLa {
  width: 20%;
  text-align: center;
  line-height: 3.125rem;
  background-color: #fff;
  color: #4494ff;
}

.next_tabLa {
  background-color: #4494ff;
  color: #fff;
}

.next_tabR,
.next_tabRa {
  width: 30%;
  text-align: center;
  line-height: 3.125rem;
  background-color: #4494ff;
  color: #fff;
  font-size: 0.875rem;
}

.next_tabRa {
  background-color: #fff;
  color: #000;
}
</style>
<style>
.myInfo_center .van-field__label {
  width: 6.2em;
}
.myInfo_center .van-cell {
  line-height: 2.4rem;
  font-size: 1rem;
}
.myInfo_center .van-field__control {
  overflow: hidden;
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
.van-cell--borderless::after,
.van-cell:last-child::after {
  display: block;
}
.van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}
.van-dialog__message {
  line-height: 1rem;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #fff;
  background-color: #1989fa;
}
.van-dialog__cancel {
  background-color: #ebedf0;
}
.van-dialog__confirm,
.van-dialog__cancel {
  height: 2rem;
}
</style>
